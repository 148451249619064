<template>
  <div v-loading="loading">
    <el-row>
      <el-col class="cols">
        <el-card>
          <el-button @click="edit" v-if="model.can_be_edited" type="success">
            Изменить
          </el-button>
          <el-button v-if="model.can_request" type="success" @click="requestModalDialog = true">
            Запросить доступ
          </el-button>
          <el-button v-if="model.requests.length > 0" type="success" @click="requestsTableDialog = true">
            Показать запросы
          </el-button>
          <el-divider/>
          <div class="f-s-14-w-s-p-l">
            <b>Наименование КП:</b>
            <p class="m-t-5 f-s-18-w-b-n">
              {{ getValue(model.pki_name) }}
            </p>
            <b>Индекс КП:</b>
            <p class="m-t-5">
              {{ getValue(model.pki_index) }}
            </p>
            <b>Расчёт:</b>
            <p class="m-t-5">
              <katex-element v-if="model.formula.indexOf('`') >= 0" :expression="getFormula(model.formula)"/>
              <span v-else> {{ model.formula }}</span>
            </p>
            <b>Результат:</b>
            <p class="m-t-5">
              {{ getValue(model.result) }}
            </p>
            <b>Балл:</b>
            <p class="m-t-5">
              {{ getValue(model.rating) }}
            </p>
            <div>
              <b>Комментарий оператора ввода:</b>
              <p class="m-t-5">
                {{ getValue(model.reason_by_operator) }}
              </p>
            </div>
            <div v-if="model.classification_id === 4">
              <b>Комментарий ИОГВ:</b>
              <p class="m-t-5">
                {{ getValue(model.reason_by_ebosp) }}
              </p>
            </div>
            <b>Дата заполнения:</b>
            <p class="m-t-5">
              {{ getFormatDate(model.create_at) }}
            </p>
            <b>ИОГВ:</b>
            <p class="m-t-5">
              {{ getValue(model.iogv_name) }}
            </p>
            <b>Период:</b>
            <p class="m-t-5">
              {{ getValue(model.period_identifier) }}
            </p>
            <b>Кто заполнил:</b>
            <p class="m-t-5">
              {{ getValue(model.user_fio) }}
            </p>
            <div v-if="model.target_reachable !== null">
              <b>Направленность показателя:</b>
              <p class="m-t-5">
                {{ model.target_reachable ? 'Повышаемый' : 'Понижаемый' }}
              </p>
              <b>Присвоение балла по уровню достижения целевого значения:</b>
              <p class="m-t-5">
                {{ getValue(model.rating_by_target) }}
              </p>
            </div>
            <div v-if="model.rating_by_target">
              <b>Целевое значение:</b>
              <p class="m-t-5">
                {{ model.target_value }}
              </p>
              <b>Уровень достижения целевого значения:</b>
              <p class="m-t-5">
                {{ getValue(model.target_reach_percent) }}
              </p>
            </div>
            <div v-if="model.comment">
              <b>Комментарий:</b>
              <p class="m-t-5">
                {{ getValue(model.comment) }}
              </p>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="14" class="cols">
        <el-card class="m-b-10" v-if="rule_io.length > 0">
          <label>Значение из списка</label>
          <el-divider/>
          <simple-table
            :list="rule_io"
            name="rule_io"
            :show-pagination="false"
            :columns="ruleIoColumns"/>
        </el-card>
        <el-card class="m-b-10" v-if="values.length > 0">
          <label>Параметры</label>
          <el-divider/>
          <simple-table
            :list="values"
            name="symbols"
            :show-pagination="false"
            :columns="valuesColumns"/>
        </el-card>
        <el-card class="m-b-10" v-if="masters.length > 0">
          <label>Зависит от</label>
          <el-divider/>
          <simple-table
            :list="masters"
            name="masters"
            :show-pagination="false"
            :columns="mastersColumns"/>
        </el-card>
        <el-card class="m-b-10" v-if="rule_ro.length > 0">
          <label>Диапазоны</label>
          <el-divider/>
          <simple-table
            :list="rule_ro"
            name="rule_ro"
            :show-pagination="false"
            :columns="ruleRoColumns"/>
          <el-divider/>
        </el-card>
      </el-col>
      <el-col :span="10" class="cols">
        <el-card class="m-b-10" v-if="operator_files.length > 0">
          <label>Документы оператора ввода</label>
          <el-divider/>
          <simple-table
            :list="operator_files"
            name="operator_files"
            :show-pagination="false"
            :columns="filesColumns"/>
        </el-card>
        <el-card class="m-b-10" v-if="invalidation_files.length > 0">
          <label>Прикреплённые документы об обнулении</label>
          <el-divider/>
          <simple-table
            :list="invalidation_files"
            name="operator_files"
            :show-pagination="false"
            :columns="filesColumns"/>
        </el-card>
        <el-card class="m-b-10" v-if="iogv_files.length > 0">
          <label>Документы оцениваемого ИОГВ</label>
          <el-divider/>
          <simple-table
            :list="iogv_files"
            name="operator_files"
            :show-pagination="false"
            :columns="filesColumns"/>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog
      title="Основание для обнуления"
      :visible.sync="dialogVisible"
      width="30%">
      <el-form :model="resetForm" ref="form">
        <el-form-item
          prop="file_id"
          :rules="{ required: true, message: 'Не заполнено обязательное поле', trigger: ['change', 'blur'] }">
          <file-upload v-model="resetForm.file_id" @delete="deleteFile"
                       @file-uploaded="fileUploaded" :limit="1"/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="invalidateStorage" :disabled="lockButton">Отправить</el-button>
        <el-button @click="dialogVisible = false">Отменить</el-button>
      </span>
    </el-dialog>
    <el-dialog
      v-if="model.id"
      title="Форма запроса доступа"
      :visible.sync="requestModalDialog"
      width="40%">
      <RequestModal :storage-id="model.id" @update="updateRequests"/>
    </el-dialog>
    <el-dialog
      v-if="model.requests.length > 0"
      title="Запросы"
      :visible.sync="requestsTableDialog"
      width="50%">
      <el-table :data="model.requests">
        <el-table-column label="ID" prop="id" width="75px"/>
        <el-table-column label="Сообщение" prop="message"/>
        <el-table-column label="Статус" prop="status.name" align="center"/>
        <el-table-column label="Файл" align="center">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.file_id"
              type="text"
              title="Посмотреть файл"
              icon="el-icon-download"
              @click="downloadFile(scope.row.file_id)"/>
            <span v-else>Не приложен</span>
          </template>
        </el-table-column>
        <el-table-column label="Действия" align="center">
          <el-button
            v-if="scope.row.status_id <= 2"
            slot-scope="scope"
            type="danger"
            @click="cancelRequest(scope.row.id)">
            Отменить запрос
          </el-button>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { cancelRequest, getStorageKpView, invalidateStorageKp, validateStorageKp } from '@/api/api'
import FileUpload from '@/components/FileUpload/Files'
import SimpleTable from '@/components/SimpleTable'
import AsciiMathParser from 'asciimath2tex'
import RequestModal from '@/views/request/RequestModal'
import { downloadFile } from '@/api/file'
import userCan from '@/utils/checkPermission'
import { getFormatDate } from '@/utils'

export default {
  components: {
    SimpleTable,
    FileUpload,
    RequestModal
  },
  data () {
    return {
      dialogVisible: false,
      requestModalDialog: false,
      requestsTableDialog: false,
      lockButton: false,
      resetForm: {
        id: null,
        file_id: null
      },
      model: {
        id: null,
        requests: [],
        can_request: null,
        can_be_edited: null,
        can_validate: null,
        classification_id: null,
        comment: null,
        create_at: null,
        ebosp_id: null,
        formula: '',
        information_on_strategic_planning_documents: null,
        international_comparisons_of_indicators: null,
        iogv_name: null,
        last_editor: null,
        period_id: null,
        period_identifier: null,
        pki_id: null,
        rating: null,
        reason_by_ebosp: null,
        reason_by_operator: null,
        result: null,
        target_reach_percent: null,
        target_value: null,
        target_reachable: null,
        toris_user_id: null,
        user_fio: null
      },
      rule_io: [],
      rule_ro: [],
      values: [],
      masters: [],
      invalidation_files: [],
      iogv_files: [],
      operator_files: [],
      loading: false,
      ruleIoColumns: [
        {
          field: 'value',
          label: 'Значение',
          align: 'left'
        },
        {
          field: 'rating',
          label: 'Балл',
          align: 'left'
        }
      ],
      ruleRoColumns: [
        {
          field: 'equals_min',
          label: 'Знак минимума',
          type: 'equals_min',
          align: 'left'
        },
        {
          field: 'input_min',
          label: 'Минимальное значение диапазона',
          align: 'left'
        },
        {
          field: 'equals_max',
          label: 'Знак максимума',
          type: 'equals_max',
          align: 'left'
        },
        {
          field: 'input_max',
          label: 'Максимальное значение диапазона',
          align: 'left'
        },
        {
          field: 'rating',
          label: 'Балл',
          align: 'left'
        }
      ],
      valuesColumns: [
        {
          field: 'description',
          label: 'Описание',
          align: 'left'
        },
        {
          field: 'designatio',
          label: 'Обозначение',
          align: 'left'
        },
        {
          field: 'value',
          label: 'Значение',
          align: 'left'
        },
        {
          field: 'comment',
          label: 'Интерпретация вводимых значений',
          align: 'left'
        }
      ],
      mastersColumns: [
        {
          field: 'pki_index',
          label: 'Индекс КП',
          align: 'left'
        },
        {
          field: 'result',
          label: 'Результат',
          align: 'left'
        }
      ],
      filesColumns: [
        {
          field: 'name',
          label: 'Файл',
          type: 'file',
          align: 'left'
        },
        {
          field: 'created_at',
          type: 'date',
          label: 'Дата создания',
          align: 'left'
        },
        {
          field: 'user_fio',
          label: 'Добавил',
          align: 'left'
        }
      ]
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    getFormatDate: getFormatDate,
    userCan: (roles) => userCan(roles),
    invalidateStorage () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.lockButton = true
          invalidateStorageKp(this.resetForm).then((response) => {
            this.$message({
              message: 'Успешно обнулено!',
              type: 'success'
            })
            this.fetchData()
          }).finally(() => {
            this.lockButton = false
            this.dialogVisible = false
            this.resetForm.file_id = null
          })
        }
      })
    },
    validateStorage () {
      validateStorageKp(this.resetForm).then((response) => {
        this.$message({
          message: 'Успешно восстановлено!',
          type: 'success'
        })
        this.fetchData()
      }).finally(() => {
        this.resetForm.file_id = null
      })
    },
    fetchData () {
      this.loading = true
      getStorageKpView(this.$route.params.id).then(response => {
        this.model = response.data.model
        this.rule_io = response.data.rule_io
        this.masters = response.data.masters
        this.rule_ro = response.data.rule_ro
        this.values = response.data.values
        this.invalidation_files = response.data.invalidation_files
        this.iogv_files = response.data.iogv_files
        this.operator_files = response.data.operator_files
        this.loading = false
        this.resetForm.id = this.model.id
      })
    },
    getValue (val) {
      if ((typeof val) === 'boolean') {
        return val ? 'Да' : 'Нет'
      }
      if (val) {
        return val
      }
      return '\u2014'
    },
    getFormula (val) {
      val = val.replaceAll('`', '')
      const parser = new AsciiMathParser()
      return parser.parse(val).replaceAll('∑', 'Σ')
    },
    getArray (arr) {
      if (arr.length > 0) {
        return arr.join(', ')
      }
      return '\u2014'
    },
    edit () {
      this.$router.push({ name: this.$route.name.replace('view', 'update') })
    },
    deleteFile () {
      this.resetForm.file_id = null
      this.$refs.form.validateField('file_id')
    },
    fileUploaded (id) {
      this.resetForm.file_id = id
      this.$refs.form.validateField('file_id')
    },
    downloadFile (id) {
      downloadFile(id).then(({ data }) => {
        const url = URL.createObjectURL(new Blob([data], { type: data.type }))
        window.open(url, '_blank')
      })
    },
    updateRequests (form) {
      this.requestModalDialog = false
      this.model.can_request = false
      this.model.requests.push(form)
    },
    cancelRequest (id) {
      this.$confirm('Вы действительно хотите отменить запрос?', 'Внимание', {
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        type: 'warning'
      }).then(() => {
        cancelRequest(id).then((response) => {
          this.model.requests = this.model.requests.map(x => x.id === response.data.id ? response.data : x)
          this.$message.success('Запрос успешно отменен!')
        })
      })
    }
  }
}
</script>

<style scoped>
.cols {
  padding: 5px;
}

.check-label >>> label {
  line-height: 1.2;
  word-break: break-word;
  margin-bottom: 5px;
  font-weight: 100;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-t-5 {
  margin-top: 5px;
  line-height: 1.5;
}

.f-s-14-w-s-p-l {
  font-size: 14px;
  white-space: pre-line;
}
</style>
