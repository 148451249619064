<template>
  <el-form :model="form" ref="form" :rules="rules" label-position="top">
    <el-form-item prop="message" label="Сообщение">
      <el-input type="textarea" maxlength="500" v-model="form.message"/>
    </el-form-item>
    <el-form-item prop="file_id" label="Прикрепление файла в формате PDF">
      <file-upload mime-type="application/pdf" v-if="!loading" v-model="form.file_id" @delete="deleteFile"
                   @file-uploaded="fileUploaded" :limit="1"/>
    </el-form-item>
    <el-form-item>
      <el-button type="success" @click="createRequest">Отправить запрос</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import FileUpload from '@/components/FileUpload/Files'
import { createRequest } from '@/api/api'

export default {
  name: 'RequestModal',
  components: {
    FileUpload
  },
  props: {
    storageId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      form: {
        message: null,
        file_id: null,
        storage_id: null
      },
      rules: {
        message: [
          {
            validator: (rule, value, callback) => {
              if (value?.trim() === '' || !value) {
                callback(new Error('Не заполнено обязательное поле'))
              } else {
                callback()
              }
            },
            required: true,
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    createRequest () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.storage_id = this.storageId
          createRequest(this.form).then((response) => {
            this.$emit('update', response.data)
            this.$message.success({
              message: `Ваш запрос на доступ к показателю в закрытый отчетный период отправлен в
            Контрольное управление Администрации Губернатора Санкт-Петербурга (ID запроса ${response.data.id}).
            Информация о статусе запроса будет направлена на Ваш электронный адрес!`,
              duration: 10000,
              showClose: true
            })
          })
        }
      })
    },
    deleteFile () {
      this.form.file_id = null
    },
    fileUploaded (id) {
      this.form.file_id = id
    }
  }
}
</script>

<style scoped>

</style>
